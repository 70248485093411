import React, { useCallback, useRef, useState } from "react";
import { DataTableComponent, PrimaryButton } from '../../components';
import { NavLink, useLoaderData } from "react-router-dom";
import EditIcon from "../../assets/images/icons/EditIcon.jsx";
import { getAllCatData, ImportCatInfo } from "../../store/category/categoryAction.js";
import store from "../../store/index.jsx";
import moment from "moment";
import { promiseToast } from "../../utils/Toast.jsx";
import { useDispatch } from "react-redux";
import { utils, writeFile } from "xlsx";

const CategoryListing = () => {
  const catRecords = useLoaderData();
  const dispatch = useDispatch();
  const [catData, setCatData] = useState(catRecords);
  const [searchText, setSearchText] = useState('');
  const selectRef = useRef(null);
	const ref = useRef(null)
  
  const handleReset = useCallback((e) => {
    let initialValues = {
      nameOrder_val: null,
      role_val: null,
      idara_val: null,
      user_type_val: null,
      category_val: null,
    }

    selectRef?.current?.clearValue();
    // fetchEmpData(initialValues)
  }, []);

  const columns = [
    {
      key: "sr_no",
      name: 'Sr. No.',
      cell: row => row.id,
      width: "5%",
    },
    {
      key: "name",
      name: 'Category Name',
      cell: row => <><p>{row.name}</p></>,
    },
    {
      key: "category_image",
      name: 'Image',
      cell: row => <><img className="image_view" src={`${process.env.REACT_APP_BASE_URL}uploads/category/${row.image}`} crossorigin="anonymous" /></>,
      width: "8%",
    },
    {
      key: "product",
      name: 'Products',
      cell: row => <><p>{row.productCount}</p></>,
      width: "8%",
    },
    {
      key: "product",
      name: 'Regular',
      cell: row => <><p>{row.tier1 || '0'}</p></>,
      width: "7%",
    },
    {
      key: "product",
      name: 'Pune City',
      cell: row => <><p>{row.tier2 || '0'}</p></>,
      width: "7%",
    },
    {
      key: "product",
      name: 'Tier 3',
      cell: row => <><p>{row.tier3 || '0'}</p></>,
      width: "7%",
    },
    {
      key: "product",
      name: 'Tier 4',
      cell: row => <><p>{row.tier4 || '0'}</p></>,
      width: "7%",
    },
    {
      key: "product",
      name: 'Tier 5',
      cell: row => <><p>{row.tier5 || '0'}</p></>,
      width: "7%",
    },
    {
      key: "updated_at",
      name: 'Updated On',
      cell: row => moment(row.updatedAt).format('DD MMM, YYYY hh:mm A'),
      width: "16%",
    },
    {
      key: "action",
      name: 'Action',
      cell: row => {
        return (
          <>
            {
            <NavLink to={`/category/edit/${row.id}`} className='action-icon' aria-label="edit" > <EditIcon height={15} width={15} /></NavLink>
            }
          </>
        )
      },
      width: "5%",
    }
  ];

  const formattedData = catData.map(item => ({
    ...item,
    updatedAt: moment(item.updatedAt).format('DD MMM, YYYY hh:mm A')
  }));

  const filteredData = formattedData.filter(item =>
    Object.values(item).some(value =>
      value !== null && value !== undefined && 
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const [excelFile, setExcelFile] = useState(null);

  const handleImageChange = (event) => {
    let file;
    file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
        };
        reader.readAsDataURL(file);
        setExcelFile(file);
    }
    
  };

  const submitform = async () => {
    let formVal = new FormData();
    formVal.append("file", excelFile);

    await promiseToast(
      dispatch(ImportCatInfo({ formVal })),
      "Uploading...",
      "Category Added Successfully!"
    );
    setExcelFile(null);
    ref.current.click();
    let return_data = await store.dispatch(getAllCatData());
    setCatData(return_data.payload.category);
  };

  const Edata = [
    ["name", "image", "tier1", "tier2", "tier3", "tier4", "tier5"]
  ];
  
  const exportToExcel = () => {
    const worksheet = utils.aoa_to_sheet(Edata);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, "sample.xlsx");
  };

    
  return (
    <>
      <div className="page-title">
        <h2>All Categories</h2>
      </div>
      <div className="row listing_wrapper">
        <div className="col-md-3">
            <input type="search" className="table_search" placeholder="Search Anything.."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)} />
        </div>
        <div className="col-md-9">
          <div className="filter-section">
            
            <h6 className="w-25 mb-0 pt-1">Total Categories : { filteredData.length }</h6>
            <div className="action-btn">
                <NavLink to="">
                  <PrimaryButton
                    textTransform={"capitalize"}
                    background={"#fff"}
                    height={"30px"}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Import
                  </PrimaryButton>
                </NavLink>
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header p-0 mb-4">
                        <h4 className="modal-title" id="exampleModalLabel">
                          Import Category
                        </h4>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          ref={ref}
                        ></button>
                      </div>
                      <div className="modal-body p-0">
                        <form action="" >
                          <div className="w-50 m-auto">
                            <input type="file" className="file_field" name="" id="" onChange={handleImageChange} />
                          </div>

                          <div className="col-md-12 text-center mt-3">
                            <button
                              type="button"
                              className="edit_button  me-3"
                              onClick={submitform}
                            >
                              Submit
                            </button>
                            <button className="edit_button text-decoration-none" onClick={exportToExcel}>Download XLSX</button>
                             
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="action-btn">
                <NavLink to="/category/add">
                  <PrimaryButton
                    textTransform={"capitalize"}
                    background={"#fff"}
                    height={"30px"}
                  >
                    Add +
                  </PrimaryButton>
                </NavLink>
            </div>
          </div>
        </div>
        </div>
        <div className="table_wrapper mt-3">
            <DataTableComponent
                columns={columns}
                data={filteredData}
                pagination
            />

        </div>
    </>
  );
};

export default CategoryListing;


export async function fetchCatData() {
  let return_data = await store.dispatch(getAllCatData());
  const user = return_data.payload.category;
  return user || null;
}