import React from "react";
import { NavLink, useLoaderData } from "react-router-dom";
import { PrimaryButton } from "../../components";
import { EditIcon } from "../../assets/images/icons";
import store from "../../store";
import { getAllBannerData } from "../../store/banner/bannerAction";

const BannerListing = () => {
  const bannerInfo = useLoaderData();

  return (
    <>
      <div className="page-title">
        <h2>All Banner</h2>
      </div>
      <div className="row listing_wrapper">
        <div className="col-md-3"></div>
        <div className="col-md-9">
          <div className="filter-section">
            <div className="action-btn">
              <NavLink
                to="/banner/add"
              >
                <PrimaryButton
                  textTransform={"capitalize"}
                  background={"#fff"}
                  height={"30px"}
                >
                  Add +
                </PrimaryButton>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="listing_data_2 mt-3">
        <div className="row">
          {bannerInfo.bannerData &&
            bannerInfo.bannerData.map((data) => {
              return (
                <>
                  <div className="col-md-4">
                    <div className="banner_image">
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}uploads/banner/${data.image}`}
                        alt=""
                        crossorigin="anonymous"
                      />
                      <div className="hover_item">
                        <NavLink
                          to={`edit/${data.id}`}
                          className="action-icon"
                        >
                          {" "}
                          <EditIcon height={15} width={15} />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default BannerListing;

export async function fetchBannerInfo() {
  let return_banner_data = await store.dispatch(getAllBannerData());
  let bannerData = return_banner_data.payload.banner;

  return {
    bannerData: bannerData,
  };
}
