import React, { useCallback, useRef, useState } from "react";
import { Calendar, DataTableComponent, PrimaryButton, Reset } from '../../components';
import CsvIcon from "../../assets/images/icons/CsvIcon.jsx";
import { NavLink, useLoaderData } from "react-router-dom";
import EditIcon from "../../assets/images/icons/EditIcon.jsx";
import Valid from "../../assets/images/icons/Valid.jsx";
import InValid from "../../assets/images/icons/InValid.jsx";
import store from "../../store/index.jsx";
import { getAllLeadData } from "../../store/lead/leadAction.js";
import moment from "moment";
import { CSVLink } from "react-csv";

const LeadListing = () => {
  const leadRecords = useLoaderData();

  let leadData = [];
  leadRecords.lead.map((data) => {    
    leadData.push({
      id:data.id,
      order_id:data.oid,
      name:data.user.username,
      email:data.user.email,
      number:data.user.number,
      companyName:data.user.companyName,
      state:data.user.State.name,
      city:data.user.city,
      pincode:data.user.pincode,
      totalQty:data.totalQty,
      price:data.total,
      updatedAt:data.updatedAt,
      status:data.status,
    });
  });  
  const [leadTableData, setLeadTableData] = useState(leadData);
  const [searchText, setSearchText] = useState('');
  const [filterData, setFilterData] = useState({
    status_val: "",
    start_date: null,  
    end_date: null,  
  });

  const handleDateChange = (dates) => {
    setFilterData(prev => ({
      ...prev,
      start_date: dates[0]?.format('YYYY-MM-DD'),
      end_date: dates[1]?.format('YYYY-MM-DD'),
    }));
  };

  const filterHandler = (event) => {
    setFilterData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };

  const filteredData = leadTableData.filter(item => {
    const matchesSearchText = Object.values(item).some(value =>
      value !== null && value !== undefined &&
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    );    

    const matchesStatus = filterData.status_val === "" || item.status === parseInt(filterData.status_val);
    const matchesDate = (!filterData.start_date || moment(item.updatedAt).isSameOrAfter(filterData.start_date)) &&
                        (!filterData.end_date || moment(item.updatedAt).isSameOrBefore(filterData.end_date));

    return matchesSearchText && matchesStatus && matchesDate;
  });


  const csvData = leadTableData.map((item) =>{
    const { deletedAt, createdAt, updatedAt, ...itemWithoutStateId } = item;
    
    switch (parseInt(itemWithoutStateId.status)) {
      case 0:
        itemWithoutStateId.status = 'Pending';
        break;
      case 1:
        itemWithoutStateId.status = 'Placed';
        break;
      case 2:
        itemWithoutStateId.status = 'Dispatched';
        break;
      case 3:
        itemWithoutStateId.status = 'Confirmed';
        break;
    
    }
    return itemWithoutStateId;
  });

    const selectRef = useRef(null);
  
  const handleReset = useCallback((e) => {
    let initialValues = {
      status_val: "",
      start_date: null,  
      end_date: null, 
    }
    setFilterData(initialValues);

    selectRef?.current?.clearValue();
    // fetchEmpData(initialValues)
  }, [ setFilterData]);


  const columns = [
    {
      key: "order_id",
      name: 'Order Id',
      cell: row => row.order_id,
      width: "10%",
    },
    {
      key: "name",
      name: 'Name',
      cell: row => <><p>{ row.name }</p><p>{ row.companyName }</p></>,
      // width: "17%",
    },
    {
      key: "contact_detail",
      name: 'Contact Details',
      cell: row => <><p>{ row.number }</p><p>{ row.email }</p></>,
      // width: "17%",
    },
    {
      key: "address",
      name: 'Address',
      cell: row => <><p>{ row.state }</p><p>{ row.city } - { row.pincode }</p></>,
      width: "10%",
    },
    {
      key: "value",
      name: 'Quantity',
      cell: row => row.totalQty,
      width: "7%",
    },
    {
      key: "total_price",
      name: 'Total Price',
      cell: row => <>&#x20b9; { row.price }</>,
      width: "9%",
    },
    {
      key: "updated_at",
      name: 'Updated On',
      cell: (row) => moment(row.updatedAt).format('DD MMM, YYYY hh:mm A'),
      width: "16%",
    },
    {
      key: "status",
      name: 'Status',
      cell: (row) =>  <>
      { row.status === 0 && 
        <><p className="status_action yellow_status">Placed</p></>
      }
      { row.status === 1 && 
        <><p className="status_action confirm_status">Confirmed</p></>
      }
      { row.status === 2 && 
        <p className="status_action publish_status">Dispatched</p>
      }
      { row.status === 3 && 
        <p className="status_action pending_status">Cancelled</p>
      }
      </>,
      width: "10%",
    },
    {
      key: "action",
      name: 'Action',
      cell: row => {
        return (
          <>
            {
            <NavLink to={`/leads/edit/${row.id}`} className='action-icon' aria-label="edit" > <EditIcon height={15} width={15} /></NavLink>
            }
          </>
        )
      },
      width: "5%",
    }
  ];

    
  return (
    <>
      <div className="page-title">
        <h2>All Leads</h2>
      </div>
      <div className="row listing_wrapper">
        <div className="col-md-3">
            <input
              type="search"
              className="table_search"
              placeholder="Search Anything.."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
        </div>
        <div className="col-md-9">
          <div className="filter-section">
            <div>
            <select name="status_val" id="status_val" className="field_dropdown" value={filterData.status_val} onChange={filterHandler}>
                <option value="">Select Status</option>
                <option value="0">Placed</option>
                <option value="1">Confirmed</option>
                <option value="2">Dispatched</option>
                <option value="3">Cancelled</option>
            </select>
            </div>
            <Calendar
              inputRef={selectRef}
              format="YYYY-MM-DD"
              range
              rangeHover
              onChange={handleDateChange}
            />
            <Reset height='30px' padding='0 6px' onClick={handleReset} />

            <div className="download-sec">
            <button type="button" className="icon-wrapper">
                <CSVLink
                  data={csvData}
                  filename="lead_data.csv"
                  target="_blank"
                >
                  <CsvIcon height={20} width={20} />
                </CSVLink>
                </button>
            </div>
          </div>
        </div>
        </div>
        <div className="table_wrapper mt-3">
            <DataTableComponent
                columns={columns}
                data={filteredData}
            />

        </div>
    </>
  );
};

export default LeadListing;


export async function fetchLeadData() {
  let return_data = await store.dispatch(getAllLeadData());
  const lead = return_data.payload;
  return lead;
}