import * as React from "react"
const WebinarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ||20}
  height={props.height ||20}
    viewBox="0 0 72 72"
    {...props}
  >
    <path d="M64.9 6.7H7.1c-2.3 0-4.1 1.8-4.1 4v37.5c0 2.2 1.8 4.1 4.1 4.1H65c2.2 0 4.1-1.8 4.1-4.1V10.7c-.1-2.2-1.9-4-4.2-4zM67 48.2c0 1.1-.9 2.1-2.1 2.1H7.1C6 50.3 5 49.4 5 48.2V10.7c0-1.1.9-2.1 2.1-2.1H65c1.1 0 2.1.9 2.1 2.1v37.5z" />
    <path d="M36 12.9c-9.1 0-16.5 7.4-16.5 16.6C19.5 38.6 26.9 46 36 46s16.5-7.4 16.5-16.5c0-9.2-7.4-16.6-16.5-16.6zM36 44c-8 0-14.5-6.5-14.5-14.5S28 14.9 36 14.9s14.5 6.5 14.5 14.6C50.5 37.5 44 44 36 44z" />
    <path d="m42.1 27-8.3-5c-1.9-1.1-4.3.2-4.3 2.5v10c0 2.2 2.4 3.6 4.3 2.5l8.3-5c1.9-1.2 1.9-3.9 0-5zm-1 3.2-8.3 5c-.6.3-1.3-.1-1.3-.7v-10c0-.7.7-1.1 1.3-.7l8.3 5c.4.2.4.6.4.7s-.1.5-.4.7zM68 60.5H20.1c-.5-1.7-2.4-2.9-4.7-2.9s-4.1 1.2-4.6 2.9H4.9c-.6 0-1 .4-1 1s.4 1 1 1h5.9c.6 1.7 2.4 2.9 4.6 2.9s4.1-1.2 4.7-2.9H68c.6 0 1-.4 1-1s-.4-1-1-1zm-52.6 2.8c-1.5 0-2.8-.9-2.8-1.9s1.3-1.9 2.8-1.9 2.8.9 2.8 1.9c0 1.1-1.3 1.9-2.8 1.9z" />
  </svg>
)
export default WebinarIcon
