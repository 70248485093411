import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import ErrorPage from '../pages/Error';
import NotFound from '../pages/NotFound';
import AdminLayout from '../layout/AdminLayout';
import Login from '../pages/Login/Login';
import Forgot from '../pages/Forgot/Forgot';
import Verified from '../pages/Verified/Verified';
import ChangePassword from '../pages/ChangePassword/ChangePassword';
import ProductListing, { fetchProductData } from '../pages/Product/ProductListing';
import ProductForm, { fetchProductInfo } from '../pages/Product/ProductForm';
import UserListing, { fetchUserData } from '../pages/Users/UserListing';
import BrandListing, { fetchBrandData } from '../pages/Brands/BrandListing';
import BrandForm, { fetchBrandInfo } from '../pages/Brands/BrandForm';
import CategoryListing, { fetchCatData } from '../pages/Category/CategoryListing';
import CategoryForm, { fetchCatInfo } from '../pages/Category/CategoryForm';
import LeadListing, { fetchLeadData } from '../pages/Lead/LeadListing';
import LeadForm, { fetchLeadInfo } from '../pages/Lead/LeadForm';
import UserForm, { fetchUserInfo } from '../pages/Users/UserForm';
import BannerListing, { fetchBannerInfo } from '../pages/Banner/BannerListing';
import PrivateRoute from './PrivateRoute';
import AccessListing, { fetchAccessData } from '../pages/Access/AccessListing';
import AccessForm, { fetchAccessInfo } from '../pages/Access/AccessForm';
import Dashboard, { fetchDesktopData } from '../pages/Dashboard/Dashboard';
import QuotationListing, { fetchQuotationData } from '../pages/Quotation/QuotationListing';
import NotificationListing from '../pages/Notification/NotificationListing';
import NotificationForm, { fetchNotificationInfo } from '../pages/Notification/NotificationForm';
import BannerForm, { fetchBannerData } from '../pages/Banner/BannerForm';

const router = (accessToken) => createBrowserRouter(
    createRoutesFromElements(
        <>
            {accessToken === null &&
                <>
                    <Route index element={<Login />} />
                    <Route exact path='/forgot' element={<Forgot />} />
                    <Route exact path='/verify-email/:token' element={<Verified />} />
                    <Route path="/change-password/:token" element={<ChangePassword />} />
                </>
            }
             <Route element={<AdminLayout />} >
                <Route index path="/" loader={fetchDesktopData} element={<PrivateRoute permissions="dashboard" component={Dashboard} />} />
                <Route path="/access-panel" loader={fetchAccessData} element={<PrivateRoute permissions="products" component={AccessListing} />} />
                <Route path="/access-panel/add" loader={fetchAccessInfo} element={<PrivateRoute permissions="products" component={AccessForm} />} />
                <Route path="/access-panel/edit/:access_id" loader={fetchAccessInfo} element={<PrivateRoute permissions="products" component={AccessForm} />} />
                
                <Route path="/products" loader={fetchProductData} element={<PrivateRoute permissions="products" component={ProductListing} />} />
                <Route path="/products/add" loader={fetchProductInfo} element={<PrivateRoute permissions="products" component={ProductForm} edit={false} />} />
                <Route path="/products/:product_id" loader={fetchProductInfo} element={<PrivateRoute permissions="products" component={ProductForm} edit={true} />} />
                <Route path="/users" loader={fetchUserData} element={<PrivateRoute permissions="users" component={UserListing} />} />
                <Route path="/users/edit/:user_id" loader={fetchUserInfo} element={<PrivateRoute permissions="users" component={UserForm} />} />
                
                <Route path="/brand" loader={fetchBrandData} element={<PrivateRoute permissions="brand" component={BrandListing} />} />
                <Route path="/brand/add" element={<PrivateRoute permissions="brand" component={BrandForm} />} />
                <Route path="/brand/edit/:brand_id" loader={fetchBrandInfo} element={<PrivateRoute permissions="brand" component={BrandForm} />} />
                <Route path="/category" loader={fetchCatData} element={<PrivateRoute permissions="category" component={CategoryListing} />} />
                <Route path="/category/add" element={<PrivateRoute permissions="category" component={CategoryForm} />} />
                <Route path="/category/edit/:catId" loader={fetchCatInfo} element={<PrivateRoute permissions="category" component={CategoryForm} />} />
                <Route path="/leads" loader={fetchLeadData} element={<PrivateRoute permissions="leads" component={LeadListing} />} />
                <Route path="/leads/edit/:lead_id" loader={fetchLeadInfo}  element={<PrivateRoute permissions="leads" component={LeadForm} />} />
                <Route path="/banner" loader={fetchBannerInfo} element={<PrivateRoute permissions="banner" component={BannerListing} />} />
                <Route path="/banner/add" loader={fetchBannerData} element={<PrivateRoute permissions="banner" component={BannerForm} />} />
                <Route path="/banner/edit/:banner_id" loader={fetchBannerData} element={<PrivateRoute permissions="banner" component={BannerForm} />} />

                <Route path="/quotations" loader={fetchQuotationData} element={<PrivateRoute permissions="leads" component={QuotationListing} />} />
                <Route path="/quotations/download/:quotation_id" loader={fetchLeadInfo}  element={<PrivateRoute permissions="leads" component={LeadForm} />} />
                
                <Route path="/push-notification" loader={fetchNotificationInfo} element={<PrivateRoute permissions="leads" component={NotificationForm} />} />
                {/* <Route path="/push-notification/add" element={<PrivateRoute permissions="leads" component={NotificationForm} />} /> */}
            </Route>
            <Route path="*" element={<NotFound />} />
        </>
    ),
    { errorElement: <ErrorPage /> }
);

export default router;
