import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import moment from 'moment';
import RobotoRegular from '../../assets/fonts/fonts/Roboto-Regular.ttf'
Font.register({ family: 'RobotoRegular', src: RobotoRegular });

const styles = StyleSheet.create({
    page: {
      padding: 30,
    },
    // General table layout with outer border and rounded corners
    table: {
      display: 'table',
      width: 'auto',
      marginTop: 20,
      borderStyle: 'solid',
      borderWidth: 0,
      borderColor: 'red',
      borderRadius: 10, // for rounded corners on outer table
    },
    // Header row with gray background and rounded top corners
    tableRowHeader: {
      flexDirection: 'row',
      backgroundColor: '#B3B3B3',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    // Style for header cells with fixed widths
    tableColHeader: {
      padding: 8,
      fontSize: 10,
      color: 'black',
      borderRightWidth: 0,
      borderRightColor: '#E0E0E0',
    },
    // Table body row with borders and fixed widths
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#E0E0E0',
    },
    tableCol: {
      padding: 8,
      fontSize: 10,
    //   textAlign: 'center',
      borderRightWidth: 1,
      borderRightColor: '#E0E0E0',
    },
    tableColLast: {
      padding: 8,
      fontSize: 10,
    //   textAlign: 'center',
    },
    // Fixed column widths (adjust according to your needs)
    colId: {
      width: '10%', // ID column width
      fontFamily: 'RobotoRegular',
    },
    colItem: {
      width: '40%', // ITEM column width
      fontFamily: 'RobotoRegular',
    },
    colImage: {
      width: '12%',
      height: 'auto',
      borderRightWidth: 0,
      padding: 8,
    },
    colQty: {
      width: '10%', // QTY column width
      fontFamily: 'RobotoRegular',
      textAlign: 'right',
    },
    colUom: {
      width: '10%', // UOM column width
      fontFamily: 'RobotoRegular',
      textAlign: 'right',
    },
    colPerPc: {
      width: '15%', // PER PC column width
      fontFamily: 'RobotoRegular',
      textAlign: 'right',
    },
    colPerPc2: {
      width: '20%', // PER PC column width
      fontFamily: 'RobotoRegular',
      textAlign: 'right',
    },
    colTotal: {
      width: '15%', // TOTAL column width
      fontFamily: 'RobotoRegular',
      textAlign: 'right',
    },
    row: {
        flexDirection: 'row',
        marginBottom: 5,
    },
    col_4: {
        width: '40%',
    },
    col_8: {
        width: '60%',
    },
    headingText: {
        fontSize: 22,
        fontWeight: 'bold',
    },
    companyText: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    addressText: {
        fontSize: 10,
        fontFamily: 'RobotoRegular',
    },
    TotalPrice:{
        marginTop: 10,
        textAlign: 'right',
    },
    footer: {
        position: 'absolute', // Absolute positioning to place the footer at the bottom
        bottom: 20, // Position it at the bottom
        left: 0,
        right: 0,
        alignItems: 'center', // Center align horizontally
        paddingBottom: 20, // Adjust padding as needed
      },
      footerHeading: {
        fontSize: 15,
        fontWeight: 'bold',
        marginBottom: 5,
      },
      footerText: {
        fontSize: 10,
      },
  });
  
  // Create a Table Component to match the design with fixed column widths
  const QuotationPDF = ({ pdfData }) => (   
    
    <Document>
      <Page size="A4" style={styles.page}>

      <View style={styles.row}>
            <View style={styles.col_4}>
                <Text style={styles.headingText}>Quotation</Text>
            </View>
            <View style={styles.col_8}>
                <Text style={styles.companyText}>{ pdfData.companyName }</Text>
                <Text style={styles.addressText}>{ pdfData.address }</Text>
                <Text style={styles.addressText}>{ pdfData.city +' - '+ pdfData.pincode }</Text>
            </View>
        </View>
        
        {/* Table */}
        <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableRowHeader}>
                <Text style={[styles.tableColHeader, styles.colId]}>Id</Text>
                <Text style={[styles.tableColHeader, styles.colImage]}>ITEM</Text>
                <Text style={[styles.tableColHeader, styles.colItem]}></Text>
                <Text style={[styles.tableColHeader, styles.colQty]}>Qty</Text>
                <Text style={[styles.tableColHeader, styles.colUom]}>UOM</Text>
                <Text style={[styles.tableColHeader, styles.colPerPc]}>Per Pc</Text>
                <Text style={[styles.tableColHeader, styles.colPerPc2]}>Selling Price</Text>
                <Text style={[styles.tableColHeader, styles.colTotal]}>Total</Text>
            </View>
    
            {/* Table Body */}
            { pdfData?.products.map((item, key) => {
                return (
                <>
                    <View style={styles.tableRow} key={key}>
                        <Text style={[styles.tableCol, styles.colId]}>{ item.uid }</Text>
                        <Image style={[styles.tableCol, styles.colImage]} src={`${process.env.REACT_APP_BASE_URL}uploads/product/${item.thumbnailImage}`} crossOrigin="anonymous" />
                        <Text style={[styles.tableCol, styles.colItem]}>{ item.title }</Text>
                        <Text style={[styles.tableCol, styles.colQty]}>{ item.qty }</Text>
                        <Text style={[styles.tableCol, styles.colUom]}>{ item.unit }</Text>
                        <Text style={[styles.tableCol, styles.colPerPc]}>₹ { item.price }</Text>
                        <Text style={[styles.tableCol, styles.colPerPc2]}>₹ { item.selling_amount }</Text>
                        <Text style={[styles.tableColLast, styles.colTotal]}>₹ { parseInt(item.qty) * parseInt(item.selling_amount) }</Text>
                    </View>
                </>
                )
            }) }

        </View>
        <View style={styles.TotalPrice}>
            <Text style={styles.addressText}>Total: ₹ { pdfData.selling_amount }</Text>
            <Text style={styles.addressText}>*Inclusive GST</Text>
        </View>

        <View style={styles.footer}>
            <Text style={styles.footerHeading}>Thank You</Text>
            <Text style={styles.footerText}>{ moment(pdfData.createdAt).format('DD MMM, YYYY hh:mm A') }</Text>
        </View>
      </Page>
    </Document>
  );


  export default QuotationPDF;  