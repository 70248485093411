import { createSlice } from '@reduxjs/toolkit';
import { getAllQuotationData, getQuotationInfo } from './quotationAction';

const initialState = {
	loading: false,
	quotation_details: null,
	filterText: {},
	error: null,
};

const quotationSlice = createSlice({
	name: 'quotation',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllQuotationData.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllQuotationData.fulfilled, (state, action) => {
				state.loading = false;
				state.lead_details = action.payload.quotations;
				state.error = null;
			})
			.addCase(getAllQuotationData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(getQuotationInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(getQuotationInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(getQuotationInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			});
	},
});

export default quotationSlice.reducer;