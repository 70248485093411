import React, { useState } from "react";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import FileUpload from "../../components/controls/FileUpload.jsx";
import {
  getAllCatData,
} from "../../store/category/categoryAction.js";
import { errorToast, promiseToast } from "../../utils/Toast.jsx";
import { useDispatch } from "react-redux";
import store from "../../store/index.jsx";
import Swal from "sweetalert2";
import SingleDropdown from "../../components/controls/SingleDropdown.jsx";
import { getAllProductData } from "../../store/product/productAction.js";
import { DelBannerInfo, getBannerInfo, ModifyBannerInfo, StoreBannerInfo } from "../../store/banner/bannerAction.js";

const BannerForm = () => {
  const navigate = useNavigate();
  const dataReturn = useLoaderData();  
  const dispatch = useDispatch();

  const categoryList = dataReturn?.catData?.map((data) => {
    return {
      id: data.id,
      text: data.name,
    };
  });

  const productList = dataReturn?.prodData?.map((data) => {
    return {
      id: data.id,
      text: data.title,
    };
  });

  

  const [formData, setFormData] = useState({
    type: dataReturn?.bannerData?.type || "",
    refId: dataReturn?.bannerData?.refId || "",
    image: dataReturn?.bannerData?.image || "",
  });
  const [imageData, setImageData] = useState(null);


  const formHandler = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));

    if (event.target.name === "type") {
      setFormData((prev) => ({
        ...prev,
        ["refId"]: "",
      }));
    }
  };

  const handleSelectChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      ["refId"]: value,
    }));
  };

  const formSubmit = async () => {
    let formVal = new FormData();
    formVal.append("refId", formData.refId);
    formVal.append("type", formData.type);
    formVal.append("image", imageData);
    let res;

    if(dataReturn?.bannerData?.id){
      const bannerId = dataReturn?.bannerData?.id;
        res = await promiseToast(
            dispatch(ModifyBannerInfo({ formVal, bannerId })),
            "Updating Banner...",
            "Banner Updated Successfully!"
          );
    }else{
        res = await promiseToast(
            dispatch(StoreBannerInfo({ formVal })),
            "Adding Banner...",
            "Banner Added Successfully!"
          );
    }

    if (res?.payload?.error) {
        errorToast(res?.payload?.error);
      } else {
        navigate("/banner");
      }
  };
  const fileVal = (val) => {
    setImageData(val);
  };

  const deleteIInfo = async () => {
    const bannerId = dataReturn?.bannerData?.id;
    Swal.fire(
      {
        text: "Are you sure, you want to delete this record?",
        showCancelButton: true,
        confirmButtonColor: "#006bcc",
        cancelButtonColor: "#bc3535",
        focusCancel: true,
        fontSize: "20px",
        width: "30em",
        padding: "4em 0 5.25em",
      },
      {
        fontSize: "20px",
      }
    ).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(DelBannerInfo({ bannerId }));
        if (res?.payload?.error) {
          errorToast(res?.payload?.error);
        } else {
          navigate("/banner");
        }
      }
    });
  };

  return (
    <>
      <div className="page-title">
        <h2>{dataReturn?.bannerData?.id ? "Update" : "Add"} Banner</h2>
      </div>
      <div className="row form_space">
        <div className="col-md-9 ps-0">
          <div className="form_bg custom_height">
            <form action="">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-outer">
                    <label htmlFor="notification_type">Type*</label>
                    <select
                      id="notification_type"
                      name="type"
                      onChange={formHandler}
                      value={formData.type}
                    >
                      <option value="">Select Type</option>
                      <option value="product">Product</option>
                      <option value="category">Category</option>
                    </select>
                  </div>
                </div>
                {formData.type === "category" && (
                  <div className="col-md-6">
                    <div className="input-outer">
                      <label htmlFor="category">Category</label>
                      <SingleDropdown
                        options={categoryList}
                        placeholder="Select Category"
                        onChange={handleSelectChange}
                        value={dataReturn?.bannerData?.refId}
                      />
                    </div>
                  </div>
                )}
                {formData.type === "product" && (
                  <div className="col-md-6">
                    <div className="input-outer">
                      <label htmlFor="product">Product</label>
                      <SingleDropdown
                        options={productList}
                        placeholder="Select Product"
                        onChange={handleSelectChange}
                        value={dataReturn?.bannerData?.refId}
                      />
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
          {dataReturn?.bannerData?.id && (
            <div className="d-flex gap-2 mt-3">
              <NavLink className="delete_link" onClick={deleteIInfo}>
                Delete Banner?
              </NavLink>
            </div>
          )}
        </div>
        <div className="col-md-3  pe-0">
          <div className="right_sidebar">
            <div className="form_submit">
              <button
                type="submit"
                className="button-primary"
                onClick={formSubmit}
              >
                {dataReturn?.bannerData?.id ? "Update" : "Publish Now"}
              </button>
            </div>

            <div className="form_bg mt-3">
              <FileUpload
                label="Upload Banner Image"
                value={formData.image && `uploads/banner/${formData.image}`}
                onChange={fileVal}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerForm;

export async function fetchBannerData({ params }) {
    const { banner_id } = params;
  let catData, prodData, banner_info, bannerData;
  let { category, product } = await store.getState();

  if (category?.category_data === null) {
    let return_cat_data = await store.dispatch(getAllCatData());
    catData = return_cat_data.payload.category;
  } else {
    catData = category.category_data;
  }

  if (product?.product_details === null) {
    let return_state_data = await store.dispatch(getAllProductData());
    prodData = return_state_data.payload.products;
  } else {
    prodData = product.product_details;
  }

  if(banner_id){
    banner_info = await store.dispatch(getBannerInfo({ banner_id }));
    bannerData = banner_info.payload.banner;
  } 

  return {
    catData: catData,
    prodData: prodData,
    bannerData: bannerData,
  };
}
