import React, { useState } from "react";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import FileUpload from "../../components/controls/FileUpload.jsx";
import { DelProductInfo, getProductInfo, ModifyProductInfo, StoreProductInfo } from "../../store/product/productAction.js";
import store from "../../store/index.jsx";
import { useDispatch } from "react-redux";
import { errorToast, promiseToast } from "../../utils/Toast.jsx";
import { getAllCatData } from "../../store/category/categoryAction.js";
import { getAllBrandData } from "../../store/brand/brandAction.js";
import Swal from "sweetalert2";

const ProductForm = ({ edit }) => {

  const [imageData, setImageData] = useState(null);
  const [galleryData, setGalleryData] = useState(null);
  const navigate = useNavigate();
  const prodInfo = useLoaderData();

  const dispatch = useDispatch();
  const PrdoFeatureImg = prodInfo?.productData?.isFeatured === true ? 1 : 0;
  const [formData, setFormData] = useState({
    prod_title: prodInfo?.productData?.title || '',
    prod_id: prodInfo?.productData?.uid || '',
    prod_sub_title: prodInfo?.productData?.subTitle || '',
    unitType: prodInfo?.productData?.unit || '',
    prod_price: prodInfo?.productData?.price || '',
    desc: prodInfo?.productData?.description || '',
    category: prodInfo?.productData?.categoryId || '',
    brand: prodInfo?.productData?.brandId || '',
    status: prodInfo?.productData?.status || '0',
    featured: PrdoFeatureImg || '0',
    featured_img: prodInfo?.productData?.thumbnailImage || '',
    gallery_img: prodInfo?.productData?.images || '',
    del_gallery_img: [],
  });

  const categoryList = prodInfo?.catData.map((data) => {
    return (
      {
        id: data.id,
        text: data.name,
      }
    )
  });
  const brandList = prodInfo?.brandData.map((data) => {
    return (
      {
        id: data.id,
        text: data.brand_name,
      }
    )
  });

  const formHandler = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };
  
  const formSubmit = async () => {    
    let res = false
    let formVal = new FormData();
    formVal.append('title', formData.prod_title);
    formVal.append('uid', formData.prod_id);
    formVal.append('subTitle', formData.prod_sub_title);
    formVal.append('unit', formData.unitType);
    formVal.append('price', formData.prod_price);
    formVal.append('description', formData.desc);
    formVal.append('categoryId', formData.category);
    formVal.append('brandId', formData.brand);
    formVal.append('status', formData.status);
    formVal.append('isFeatured', formData.featured);

    if (prodInfo?.productData?.id) {
      if (imageData) {
        formVal.append('thumbnailImage', imageData);
      }
      if (galleryData) {
        for (let index = 0; index < galleryData.length; index++) {
          formVal.append('image', galleryData[index]);
        }
      }
      if (formData.del_gallery_img) {
        const DelImg = formData.del_gallery_img.map(person => person.path.split('/').pop()).join(',');
        formVal.append('del_gallery_img', DelImg);
      }

      let productId = prodInfo?.productData?.id;
      res = await promiseToast(dispatch(ModifyProductInfo({ formVal, productId })), "Updating Product Info...", "Product Data Updated Successfully!");
    } else {
      formVal.append('thumbnailImage', imageData);
      if (galleryData) {
        for (let index = 0; index < galleryData.length; index++) {
          formVal.append('image', galleryData[index]);
        }
      }
      res = await promiseToast(dispatch(StoreProductInfo({ formVal })), "Adding Product Info...", "Product Data Added Successfully!");
    }
    if (res?.error) return;
    navigate("/products")
  };
  const fileVal = (val) => {
    setImageData(val)
  }
  const GalleryVal = (val) => {
    setGalleryData(val)
  }

  const deleteIInfo = async() => {
    let productId = prodInfo?.productData?.id;
      Swal.fire({
        text: "Are you sure, you want to delete this record?",
        showCancelButton: true,
        confirmButtonColor: '#006bcc',
        cancelButtonColor: '#bc3535',
        focusCancel: true,
        fontSize: '20px',
        width: '30em',
        padding: '4em 0 5.25em'
    },
        {
            fontSize: '20px'
        }
    ).then(async (result) => {
        if (result.isConfirmed) {
            await dispatch(DelProductInfo({ productId }))
            navigate("/products")
        }
    })
  }

  return (
    <>
      <div className="page-title">
        <h2>{prodInfo?.productData?.id ? 'Update' : 'Add'} Product</h2>
      </div>
      <div className="row form_space">
        <div className="col-md-9 ps-0">
          <div className="form_bg">
            <form action="">
              <div className="row">
                <div className="col-md-9">
                  <div className="input-outer">
                    <label htmlFor="prod_title">Product Title*</label>
                    <input type="text" className="input-wrapper custom-input" id="prod_title" name="prod_title" value={formData.prod_title} onChange={formHandler} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-outer">
                    <label htmlFor="prod_id">Product Id*</label>
                    <input type="text" className="input-wrapper custom-input" id="prod_id" name="prod_id" value={formData.prod_id} onChange={formHandler} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-outer">
                    <label htmlFor="prod_sub_title">Sub Product Title*</label>
                    <input type="text" className="input-wrapper custom-input" id="prod_sub_title" name="prod_sub_title" value={formData.prod_sub_title} onChange={formHandler} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="input-outer">
                    <label htmlFor="unitType">Unit Type*</label>
                    <select name="unitType" onChange={formHandler} value={formData.unitType}>
                      <option value="">Select Unit</option>
                      <option value="DZN">DZN</option>
                      <option value="PCS">PCS</option>
                      <option value="SET">SET</option>
                      <option value="BOX">BOX</option>
                      <option value="NOS">NOS</option>
                      <option value="PAIR">PAIR</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-outer">
                    <label htmlFor="prod_price">{formData.unitType} Per Price*</label>
                    <input type="text" className="input-wrapper custom-input" id="prod_price" name="prod_price" value={formData.prod_price} onChange={formHandler} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-outer">
                    <label htmlFor="desc">Product Description*</label>
                    <textarea
                      name="desc"
                      id="desc"
                      className="textarea_field"
                      rows="3"
                      cols="3"
                      onChange={formHandler}
                    >{formData.desc}</textarea>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-outer">
                    <label htmlFor="category">Product Category*</label>
                    <select name="category" value={formData.category} onChange={formHandler}>
                      <option value="">Select Category</option>
                      {categoryList.map((item) => {
                        return (
                          <option value={item.id}>{item.text}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-outer">
                    <label htmlFor="brand">Product Brand*</label>
                    <select name="brand" value={formData.brand} onChange={formHandler}>
                      <option value="">Select Brand</option>
                      {brandList.map((item) => {
                        return (
                          <option value={item.id}>{item.text}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-outer">
                    <label htmlFor="">Featured*</label>
                    <div className="d-flex">
                      <div className="radia_val">
                        <input type="radio" name="featured" value="1" onChange={formHandler} checked={formData.featured == 1 ? true : false} />
                        <span>Yes</span>
                      </div>
                      <div className="radia_val">
                        <input type="radio" name="featured" value="0" onChange={formHandler} checked={formData.featured == 0 ? true : false} />
                        <span>No</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

          </div>
          <div className="d-flex gap-2 mt-3">
            <NavLink className="delete_link" onClick={deleteIInfo}>Delete Product?</NavLink>
          </div>
        </div>
        <div className="col-md-3  pe-0">
          <div className="right_sidebar">
            <div className="form_submit">
              <div className="input-outer">
                <select name="status" onChange={formHandler} value={formData.status}>
                  <option value="0">Draft</option>
                  <option value="1">Published</option>
                </select>
              </div>
              <button type="submit" className="button-primary" onClick={formSubmit}>
                {edit ? 'Update' : 'Submit'}
              </button>
            </div>

            <div className="form_bg mt-3">
              <FileUpload label="Upload Feature Image" onChange={fileVal} value={formData.featured_img && `uploads/product/${formData.featured_img}`} />
            </div>

            <div className="form_bg mt-3">
              <FileUpload limit="3" label="Upload Gallery Images (3)" setFormData={setFormData} onChange={GalleryVal} filePath="uploads/product" value={`${formData.gallery_img}`} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductForm;

export async function fetchProductInfo({ params }) {
  const { product_id } = params;

  let return_cat_data = await store.dispatch(getAllCatData());
  let catData = return_cat_data.payload.category;

  let return_brand_data = await store.dispatch(getAllBrandData());
  let brandData = return_brand_data.payload.brand;

  if (product_id) {
    let return_data = await store.dispatch(getProductInfo({ product_id }));
    const productData = return_data.payload.product;

    return (
      {
        productData: productData,
        catData: catData,
        brandData: brandData,
      }
    );
  } else {
    return (
      {
        catData: catData,
        brandData: brandData,
      }
    );
  }

}